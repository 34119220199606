import { createBrowserRouter, Outlet, } from "react-router-dom";

import App from 'src/App';
import Dashboard from "src/pages/dashboard/Dashboard";
import { AIGraderDashboard } from "src/pages/goodpoint/dashboard/GoodPointDashboardPage";
import AssessmentPage from "src/pages/goodpoint/assessment/AssessmentPage";
import { RouteGuard } from "src/api/RouteGuard";
import { Navigate } from "react-router-dom";
import ComingSoon from "src/pages/coming-soon/ComingSoonPage";
import HomeRoot from "src/pages/home/Root";
import Home from "src/pages/home/Home";
import About from "src/pages/home/About";
import Blog from "src/pages/home/Blog";
import GoodPointProduct from "src/pages/home/products/GoodPoint"
import NewsWitchProduct from "src/pages/home/products/NewsWitch"
import ExamMasterProduct from "src/pages/home/products/ExamMaster"
import DissertationCopilotProduct from "src/pages/home/products/DissertationCopilot"
import Pricing from "src/pages/home/Pricing";
import Legal from "src/pages/home/Legal";
import { PreCheckoutForm } from "src/pages/payment/Checkout";
import { CheckoutForm } from "src/pages/payment/CheckoutForm";
import { CheckoutReturn } from "src/pages/payment/CheckoutReturn";
import { DailyReport, PublicReport, ReportPage } from "./pages/newswitch/ReportPages";
import { TickerInputs } from "./pages/newswitch/TickerInputs";
import { ReportsListPage } from "./pages/newswitch/ReportList";
import { JobsPage } from "./pages/newswitch/JobsPage";
import AdminPage from "./pages/admin/AdminPage";
import UserManagementPage from "./pages/admin/UserManagement";
import PublicConfigPage from "src/pages/newswitch/PublicConfigPage";
import CrawlerInfo from "src/pages/home/CrawlerInfo";
import NewsWitchPipelineManager from "./pages/admin/NewsWitchPipelineManager";
import NewNewsWitchDasboard from "./pages/newswitch/NewNewsWitchDashboard";
import NewsWitchDasboard from "./pages/newswitch/NewsWitchDashboard";

const ZanistaRouter = ({ msal }) => createBrowserRouter([
    {
        path: "/",
        element: <Home msal={msal} />,
        errorElement: <Navigate to="/" />
    },
    {
        path: "/",
        element: <HomeRoot msal={msal} />,
        errorElement: <Navigate to="/" />, // TODO: implement 404
        children: [
            {
                path: "about",
                element: <About />,
            },
            {
                path: "blog",
                element: <Blog />,
            },
            {
                path: "pricing",
                element: <Pricing />,
            },
            {
                path: "legal",
                element: <Legal />,
            },
            {
                path: "finance",
                element: <RouteGuard 
                            roles={["admin"]} 
                            errorElement={<ComingSoon 
                                title="Access Required" 
                                subtitle="This account does not yet have access to NewsWitch."/>} >
                            <PublicReport />
                        </RouteGuard>,
            },
            {
                path: "crawler-info",
                element: <CrawlerInfo />,
            },
            {
                path: "products/goodpoint",
                element: <GoodPointProduct />,
            },
            {
                path: "products/exam-master",
                element: <ExamMasterProduct />,
            },
            {
                path: "products/dissertation-copilot",
                element: <DissertationCopilotProduct />,
            },
            {
                path: "products/newswitch",
                element: <NewsWitchProduct />,
            },
        ]
    },
    {
        path: "/",
        element: <App msal={msal} />,
        errorElement: <Navigate to="/" />, // TODO: implement 404
        children: [
            {
                path: "dashboard",
                element: 
                    <Dashboard />
            },
            {
                path: "goodpoint",
                element: <RouteGuard
                            errorElement={<ComingSoon 
                                title="Thanks for signing up" 
                                subtitle="We will be in touch shortly to approve your request to access GoodPoint."
                                buttons={[
                                    {
                                        link: "mailto:info@zanista.ai?subject=GoodPoint Access Request&body=Hi, I would like to access GoodPoint. Please reach out to me by email.",
                                        text: "Email us"
                                    },
                                    {
                                        text: "Find out more",
                                        link: "https://zanista.ai"
                                    }
                                ]} />
                            }
                        >
                            <Outlet />
                        </RouteGuard>,
                children: [
                    {
                        path: "",
                        element: <AIGraderDashboard />
                    },
                    {
                        path: "assessment/:assessmentId",
                        //params: ["assessmentId"],
                        element: <AssessmentPage />
                    }
                ]
            },
            {
                path: "newswitch",
                element: <RouteGuard 
                            roles={["admin"]} 
                            errorElement={<ComingSoon 
                                            title="Access Required" 
                                            subtitle="This account does not yet have access to NewsWitch."/>} >
                            <Outlet />
                        </RouteGuard>,
                children: [
                    {
                        path: "",
                        element: <NewsWitchDasboard />
                    },
                    {
                        path: "search",
                        element: <NewNewsWitchDasboard />
                    },
                    {
                        path: "inputs",
                        element: <TickerInputs />
                    },
                    {
                        path: "reports",
                        element: <ReportsListPage />
                    },
                    {
                        path: "report",
                        element: <ReportPage />
                    },
                    {
                        path: "latest",
                        element: <DailyReport />
                    },
                    {
                        path: "jobs",
                        element: <JobsPage />
                    },
                    {
                        path: "public-config",
                        element: <RouteGuard 
                                    roles={["admin"]}
                                    errorElement={<ComingSoon 
                                        title="Access Required" 
                                        subtitle="This account does not have access to this page."/>}
                                >
                                    <PublicConfigPage />
                                </RouteGuard>
                    },
                    {
                        path: "pipeline-tracker",
                        element: <RouteGuard 
                                    roles={["admin"]}
                                    errorElement={<ComingSoon 
                                        title="Access Required" 
                                        subtitle="This account does not have access to this page."/>}
                                >
                                    <NewsWitchPipelineManager />
                                </RouteGuard>
                    }
                ]
            },
            {
                path: "checkout",
                element: <RouteGuard errorElement={<Navigate to="/dashboard" />} >
                            <Outlet />
                        </RouteGuard>,
                children: [
                    {
                        path: "",
                        element: <PreCheckoutForm />
                    },
                    {
                        path: "payment",
                        element: <CheckoutForm />
                    },
                    {
                        path: "return",
                        element: <CheckoutReturn />
                    }
                ]
            },
            {
                path: "admin",
                element: <RouteGuard 
                            roles={["admin"]}
                            errorElement={<ComingSoon 
                                title="Access Required" 
                                subtitle="This account does not have access to this page."/>}
                        > 
                            <Outlet />
                        </RouteGuard>,
                children: [
                    {
                        path: "",
                        element: <AdminPage />
                    },
                    {
                        path: "users",
                        element: <UserManagementPage />
                    }
                ]
            }
        ]
    }
]);

export default ZanistaRouter
