import { useContext, useState } from "react";
import { InfoCircleSolid, TrashSolid, LineSpace, Xmark } from "iconoir-react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { EDIT_MODES } from "src/pages/goodpoint/assessment/AssessmentContext";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import AreYouSureModal from "./AreYouSureModal";
import {
  updateNestedStructure,
  makeCards,
  adjustMarksBottomUp,
} from "./Helpers";

export default function EditToolbar({
  editMode,
  setEditMode,
  setStructure,
  setCards,
  saveStructure,
  addStructureUpdate,
  enhanced = false,
}) {
  const { assessmentObject, deleteSelected, setDeleteSelected } =
    useContext(AssessmentContext);

  const [discardPopupOpen, setDiscardPopupOpen] = useState(false);

  const deleteQuestion = () => {
    let newStructure;
    setStructure((prevStructure) => {
      newStructure = { ...prevStructure };

      for (let keys_string of deleteSelected) {
        let keys = keys_string.split(","); // Convert the comma-separated string into an array
        const question = updateNestedStructure(
          newStructure,
          keys,
          {},
          "delete"
        ); // Apply deletion
        addStructureUpdate({
          action: "DELETE",
          info: { keys: keys, question_text: question.question_text },
        });
      }
      adjustMarksBottomUp(newStructure);
      return newStructure;
    });
    saveStructure(assessmentObject.id, newStructure, enhanced);
    setCards(makeCards(newStructure));
    setDeleteSelected([]);
    setEditMode(EDIT_MODES.DEFAULT);
  };

  return (
    <div className="flex flex-row gap-large mb-2">
      {editMode === EDIT_MODES.DEFAULT && (
        <>
          <Tooltip title="Change order" placement="top">
            <IconButton onClick={() => setEditMode(EDIT_MODES.REORDER)}>
              <LineSpace />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" placement="top">
            <IconButton onClick={() => setEditMode(EDIT_MODES.DELETE)}>
              <TrashSolid />
            </IconButton>
          </Tooltip>

          <div className="ml-auto">
            <Tooltip title="Click on marks to edit" placement="right">
              <span>
                <IconButton disabled>
                  <InfoCircleSolid className="text-gray-500" />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </>
      )}

      {editMode === EDIT_MODES.REORDER && (
        <>
          <div className="flex items-center gap-2">
            <Tooltip title="cancel" placement="top">
              <span>
                <IconButton onClick={() => setEditMode(EDIT_MODES.DEFAULT)}>
                  <Xmark className="text-black" />
                </IconButton>
              </span>
            </Tooltip>
            <p className="">Drag questions to change order</p>
          </div>
        </>
      )}

      {editMode === EDIT_MODES.DELETE && (
        <>
          <div className="flex items-center gap-2">
            <Tooltip title="Cancel" placement="top">
              <span>
                <IconButton
                  onClick={() => {
                    setEditMode(EDIT_MODES.DEFAULT);
                    setDeleteSelected([]);
                  }}
                >
                  <Xmark className="text-black" />
                </IconButton>
              </span>
            </Tooltip>
            <p>Select questions to delete</p>
            {deleteSelected.length > 0 && (
              <>
                <IconButton
                  onClick={() => {
                    setDiscardPopupOpen(true);
                  }}
                >
                  <TrashSolid className="text-red-500" />
                </IconButton>
              </>
            )}
          </div>
          <AreYouSureModal
            popupOpen={discardPopupOpen}
            closePopup={() => setDiscardPopupOpen(false)}
            title={"Delete questions"}
            content={"Are you sure you want to delete questions?"}
            onConfirm={deleteQuestion}
            danger={true}
            lightButton={"Delete"}
            darkButton={"Cancel"}
          />
        </>
      )}
    </div>
  );
}
