import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Title from "src/components/content/Title";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";
import StudentOverallFeedback from "src/pages/goodpoint/assessment/tabs/grading/student-performance/StudentOverallFeedback";
import { calculatePercentage } from "src/util/StringUtil";
import { LoadingCharts } from "src/components/ui/LoadingCharts";
import { getStudentQuantileImgById, saveStudentQuantileImgById } from "src/storage/GradingStorage";
import axios from "axios";

export default function StudentStats({ examResults }) {
    const { assessmentObject } = useContext(AssessmentContext);
    const assessmentId = assessmentObject.id;
    const { selectedStudent } = useContext(StudentsContext);
    const studentId = selectedStudent.student_id;

    const [studentQuantileImgSrc, setStudentQuantileImg] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state

    useEffect(() => {
        setLoading(true);

        const cachedSrc = getStudentQuantileImgById(assessmentId, studentId);
        if (cachedSrc) {
            setStudentQuantileImg(cachedSrc);
            setLoading(false);
            return;
        }

        const endpoint = GoodPointApi.Grading.Images(assessmentId, GradingImages.QUANTILE_PLOT, { student_id: studentId });
        axios.get(endpoint, { responseType: 'blob' })
            .then(response => {
                const blob = response.data;
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Img = reader.result;
                    setStudentQuantileImg(base64Img);
                    saveStudentQuantileImgById(assessmentId, studentId, base64Img);
                    setLoading(false);
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error('Error fetching class distribution image:', error);
                setLoading(false);
            });

    }, [assessmentId, studentId]);
    return (
        <div className="student-stats">
            <Title
                Size="b"
                title={selectedStudent?.name ?? "Student"}
                subtitle={`ID ${selectedStudent?.student_id}`} />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            { /* TODO: Percentage using division can be wrong due to js FP precision */}
            <p>
                <b>Total marks: </b>
                {
                    `${selectedStudent?.total_marks}/${examResults?.total_mark} | ` +
                    `${selectedStudent && examResults && calculatePercentage(selectedStudent.total_marks, examResults.total_mark)}%`
                }
            </p>
            <p><b>Grade: </b>{selectedStudent?.grade}</p>
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            <StudentOverallFeedback />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            {/* {
                <img className="cursor-pointer" src={studentQuantileImgSrc} alt="Student Quantile" onClick={() => { setPopupOpen(true); }} />
            } */}

            {loading ? (
                <LoadingCharts />
            ) : (
                <img className="cursor-pointer" src={studentQuantileImgSrc} alt="Student Quantile" onClick={() => { setPopupOpen(true); }} />

            )}

            <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
                <div className='bg-white rounded-small'>
                    <img src={studentQuantileImgSrc} alt="Student Quantile" />
                </div>
            </Popup>
        </div>
    )
}