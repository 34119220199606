import {
    BadgeCheck,
    EditPencil,
    InfoCircle,
    NavArrowDown,
    NavArrowRight,
    PageStar,
    WarningTriangle,
} from "iconoir-react";
import { useContext, useEffect, useState } from "react";
import Latex from "react-latex-next";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import IconButton from "src/components/ui/IconButton";
import RichMathEditor from "src/components/ui/RichMathEditor";
import "./SolutionSteps.css";
import {
    updateNestedStructure,
    adjustMarksBottomUp,
    tokenizeText,
} from "src/components/ui/Helpers";
// import { StepState } from "src/api/goodpoint/Const";
import { saveRubric } from "src/storage/RubricStorage";
import { AssessmentContext } from "../../../AssessmentContext";
import { Tooltip } from "@mui/material";
import StructureSection from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import RenderMath from "src/components/ui/RenderMath";
import { IconButton as MuiIconButton } from "@mui/material";
const StepTabText = {
    SOLUTION: "Solution",
    EXPECTATION: "Expectation",
    COMMON_ERRORS: "Common Errors",
    MARKING: "Marking",
};

const StepTabIcon = {
    SOLUTION: BadgeCheck,
    EXPECTATION: InfoCircle,
    COMMON_ERRORS: WarningTriangle,
    MARKING: PageStar,
};

const useStepState = (step) => {
    const [solution, setSolution] = useState({
        text: step.solution,
        isEdit: false,
    });

    const [expandedSolution, setExpandedSolution] = useState({
        text: step.expanded_solution,
        isEdit: false,
    });

    const [expected, setExpected] = useState({
        text: step.expected,
        isEdit: false,
    });

    const [commonErrors, setCommonErrors] = useState({
        text: step.common_errors,
        isEdit: false,
    });

    const [stepMark, setStepMark] = useState(step.step_mark);
    const [stepMarkDeductions, setStepMarkDeductions] = useState(
        step.step_mark_deductions
    );

    return {
        solution,
        setSolution,
        expandedSolution,
        setExpandedSolution,
        expected,
        setExpected,
        commonErrors,
        setCommonErrors,
        stepMark,
        setStepMark,
        stepMarkDeductions,
        setStepMarkDeductions,
    };
};

const EditableContent = ({ label, content, setContent }) => {
    function saveContent({ newContent }) {
        setContent((prevContent) => ({
            ...prevContent,
            text: newContent,
        }));
    }

    return (
        <div className="w-full">
            <b>{label}: </b>
            {content.isEdit && (
                <RichMathEditor
                    initialTokens={tokenizeText(content.text)}
                    saveContent={saveContent}
                    closeEditor={() =>
                        setContent((prevContent) => ({
                            ...prevContent,
                            isEdit: false,
                        }))
                    }
                />
            )}

            {!content.isEdit && (
                <>

                    <MuiIconButton
                        onClick={() => {
                            setContent((prevContent) => ({
                                ...prevContent,
                                isEdit: true,
                            }));
                        }}
                        className="float-right"
                    >
                        <EditPencil className="cursor-pointer hover:text-blue-700 float-right" />
                    </MuiIconButton>
                    <RenderMath tokens={tokenizeText(content.text)} />
                </>
            )}
        </div>
    );
};

function StepTabs({ stepTab, setStepTab }) {
    return (
        <div className="step-tabs flex-col gap-mid">
            {Object.entries(StepTabText).map(([k, v], i) => {
                const selected = stepTab === v;
                return (
                    <IconButton
                        key={i}
                        Icon={StepTabIcon[k]}
                        iconColour={selected ? "black" : "var(--orange-dark)"}
                        bgColour={selected ? "var(--orange-mid)" : "var(--yellow-light)"}
                        borderSize={"var(--border-thin)"}
                        borderColour={selected ? "black" : "transparent"}
                        roundedSize="var(--rounded-xsmall)"
                        paddingSize="2px 5px"
                        onClick={() => setStepTab(v)}
                        justifyContent="left"
                        text={v}
                        className={"justify-content-left"}
                    />
                );
            })}
        </div>
    );
}

function Step({ index, step, question_keys }) {
    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed,
        reset: resetAll,
    } = useContext(ExpandCollapseContext);

    const { isExpanded, expand, collapse, toggle } = useExpandCollapse();

    useEffect(() => {
        isAllExpanded && expand();
    }, [isAllExpanded, expand]);
    useEffect(() => {
        isAllCollapsed && collapse();
    }, [isAllCollapsed, collapse]);

    const [stepTab, setStepTab] = useState(StepTabText.SOLUTION);

    const {
        solution,
        setSolution,
        expandedSolution,
        setExpandedSolution,
        expected,
        setExpected,
        commonErrors,
        setCommonErrors,
        stepMark,
        setStepMark,
        stepMarkDeductions,
        setStepMarkDeductions,
    } = useStepState(step);

    const { assessmentObject, rubric, setRubric, addRubricUpdate } =
        useContext(AssessmentContext);
    const [warningOpen, setWarningOpen] = useState(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    //because eslint makes me do infinite loops fuck you!!! 
    useEffect(() => {
        const keys = question_keys;
        let updatedRubric = { ...rubric };
        const newValues = {
            solution: solution.text,
            expanded_solution: expandedSolution.text,
            expected: expected.text,
            common_errors: commonErrors.text,
            step_mark: stepMark,
            step_mark_deductions: stepMarkDeductions,
        };

        const steps = updateNestedStructure(
            updatedRubric,
            keys,
            newValues,
            "steps",
            index - 1
        );
        updatedRubric = adjustMarksBottomUp(updatedRubric);
        setRubric(updatedRubric);
        saveRubric(assessmentObject.id, updatedRubric, true);
        addRubricUpdate({
            action: "UPDATE_STEPS",
            info: {
                keys: keys,
                steps: steps,
            },
        });
    }, [solution, expandedSolution, expected, commonErrors, stepMark, stepMarkDeductions]);
    /* eslint-enable react-hooks/exhaustive-deps */


    const handleMarkDeductions = (event) => {
        const newMarkDeductions = parseFloat(event.target.value) || 0;
        if (newMarkDeductions > stepMark) {
            setWarningOpen(true);
        } else {
            setStepMarkDeductions(newMarkDeductions);
            setWarningOpen(false);
        }
    };

    var stepContent = null;
    switch (stepTab) {
        case StepTabText.SOLUTION:
            stepContent = (
                <div>
                    <EditableContent
                        label="Solution"
                        content={solution}
                        setContent={setSolution}
                    />
                </div>
            );
            break;

        case StepTabText.EXPECTATION:
            stepContent = (
                <div>
                    <EditableContent
                        label="Expanded Solution: "
                        content={expandedSolution}
                        setContent={setExpandedSolution}
                    />

                    <p></p>
                    <br></br>
                    <EditableContent
                        label="Student Expectation: "
                        content={expected}
                        setContent={setExpected}
                    />
                </div>
            );
            break;

        case StepTabText.COMMON_ERRORS:
            stepContent = (
                <div>
                    <EditableContent
                        label="Common Errors: "
                        content={commonErrors}
                        setContent={setCommonErrors}
                    />
                    <p></p>
                    <br></br>
                    <b>Examples: </b>
                    <ul>
                        {" "}
                        {[].concat(step.common_errors_examples).map((v, i) => {
                            return (
                                <li key={i}>
                                    <RenderMath tokens={tokenizeText(v)} />
                                </li>
                            );
                        })}{" "}
                    </ul>
                </div>
            );
            break;
        case StepTabText.MARKING:
            stepContent = (
                <div>
                    <b>Marks for this step: </b>
                    <p className="fg-orange-dark step-heading-marks text-nowrap">
                        <input
                            type="number"
                            key={`${index}`} //forces rerender
                            onBlur={(event) =>
                                setStepMark(parseFloat(event.target.value) || 0)
                            }
                            defaultValue={step.step_mark}
                            className="w-12 text-right focus:outline-accent"
                        ></input>
                        Marks
                    </p>
                    <p></p>
                    <br></br>
                    <b>Mark deductions for errors: </b>
                    <p className="fg-orange-dark step-heading-marks text-nowrap">
                        <input
                            type="number"
                            key={`${index}`} //forces rerender
                            onBlur={handleMarkDeductions}
                            defaultValue={step.step_mark_deductions}
                            className="w-12 text-right focus:outline-accent"
                        ></input>
                        Marks

                        <Tooltip
                            open={warningOpen}
                            onOpen={() => setWarningOpen(true)}
                            onClose={() => setWarningOpen(false)}
                            title="Mark deduction cannot be more than step mark!"
                        >
                            <div></div>
                        </Tooltip>
                    </p>
                </div>
            );
            break;
        default:
            break;
    }

    return (
        <div className="step flex-col gap-small">
            <div
                className="step-heading flex-row gap-mid justify-content-space-between fill-width cursor-pointer align-center"
                onClick={() => {
                    toggle();
                    resetAll();
                }}
            >
                <div className="flex-row gap-mid text-nowrap">
                    {isExpanded ? (
                        <NavArrowDown color="var(--orange-dark)" width={"1em"} />
                    ) : (
                        <NavArrowRight color="var(--orange-dark)" width={"1em"} />
                    )}
                    <p className="fg-black">Step {index}</p>
                    <div className="step-heading-text fg-orange-dark">
                        <Latex>{step.step}</Latex>
                    </div>
                </div>
                <div className="step-divider"></div>
                <p className="step-heading-marks text-nowrap fg-orange-dark">
                    {step.step_mark} Marks
                </p>
            </div>
            {isExpanded && (
                <div className="step-body flex-row gap-large">
                    <StepTabs stepTab={stepTab} setStepTab={setStepTab} />
                    <div className="step-content font-size-medium">{stepContent}</div>
                </div>
            )}
        </div>
    );
}

export default function SolutionSteps({ steps, question_keys }) {
    const expandCollapse = useExpandCollapse(false);

    if (!steps) {
        return null;
    }

    return (
        <ExpandCollapseContext.Provider value={expandCollapse}>
            <StructureSection
                heading={"Step-by-Step"}
                headingControls={
                    <ExpandCollapseButtons
                        props={{
                            iconSize: 17,
                            borderSize: "var(--border-thin)",
                            paddingSize: "0px 5px",
                            roundedSize: "var(--rounded-xsmall)",
                        }}
                    />
                }
                aiGenerated
            >
                {
                    <div className="steps-list flex-col gap-mid">
                        {steps.map((s, i) => (
                            <Step
                                key={i}
                                step={s}
                                index={i + 1}
                                question_keys={question_keys}
                            />
                        ))}
                    </div>
                }
            </StructureSection>
        </ExpandCollapseContext.Provider>
    );
}
