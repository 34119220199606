import { useMemo, useState, useContext, useEffect } from "react";
import Divider from "src/components/layout/Divider";
import { StructureItem } from "src/pages/goodpoint/assessment/components/structure/StructureItem";
import StructureSection from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import { updateNestedStructure } from "src/components/ui/Helpers";
import { setAllStudentAnswers } from "src/storage/StudentAnswerStorage";
import { AssessmentContext } from "../../../AssessmentContext";
import { findNestedQuestionText } from "src/components/ui/Helpers";
import { getRubricById } from "src/storage/RubricStorage";

function AnswerHeader({ itemData, nestingLevel }) {
  const numParts = useMemo(() => {
    const subQuestions = itemData.sub_questions;
    return Object.keys(subQuestions ?? {}).length;
  }, [itemData.sub_questions]);

  return (
    <div className="answers-item-header fill-width">
      <p className="answers-item-question-tag justify-self-left">
        <b>
          {nestingLevel > 0 ? "Part " : ""}
          {itemData.tag}
        </b>
      </p>
      {numParts > 0 && (
        <p className="answers-item-parts justify-self-right">
          {numParts} Parts
        </p>
      )}
    </div>
  );
}

function AnswerItemContent({ itemData }) {
  const { selectedStudent, setStudents, students, assessmentObject } =useContext(AssessmentContext);
  
  //To get the question text to display
  const rubric = getRubricById(assessmentObject.id); 
  const questionText = findNestedQuestionText(itemData, rubric);
 

  //need a setSolution to immediately show the change on the screen without collapsing the questions
  const [solution, setSolution] = useState(itemData.student_solution);

  //need this to ensure the content on the screen changes when u switch to another student 
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSolution(itemData.student_solution);
  }, [selectedStudent]);
  
  //need this to sync it up with the overall students object, so when u switch back to the same student
  //the change is still there
  useEffect(() => {

    if (!solution || !selectedStudent) {
      return;
    }
    const studentId = selectedStudent.student_id;
    const newValues = {
      student_solution: solution,
    };

    const updatedStudentAnswer = { ...selectedStudent["student_answers"] };
    updateNestedStructure(updatedStudentAnswer, itemData.keys, newValues, "content");

    let newStudents = {...students};
    newStudents[studentId].student_answers = updatedStudentAnswer;

    setStudents(newStudents);
    setAllStudentAnswers(assessmentObject.id, newStudents);

  }, [solution]);
   /* eslint-enable react-hooks/exhaustive-deps */


  return (
    <div className="student-answer-content">
      {questionText && (
        <StructureSection
          heading={"Question"}
          content={questionText}
          children={undefined}
          hide
        />
      )}

      {itemData.student_solution && (
        <StructureSection
          heading={"Answer"}
          content={solution}
          setContent={setSolution}
          children={undefined}
        />
      )}
      {!(
        itemData.student_solution ||
        Object.keys(itemData.sub_questions).length > 0
      ) && (
          <>
            <p>(empty)</p>
            <Divider
              lineColour="rgba(0, 0, 0, 0.3)"
              shadowColour="rgba(0, 0, 0, 0.20)"
            />
          </>
        )}
    </div>
  );
}

export default function AnswerItem(props) {
  return (
    <StructureItem
      HeaderComponent={AnswerHeader}
      ContentComponent={AnswerItemContent}
      {...props}
    />
  );
}
