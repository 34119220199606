import { useContext,  useEffect } from "react";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { StudentsSelect } from "src/pages/goodpoint/assessment/components/StudentsMenu";

import "./AnswersContent.css";
import useFetchStudentAnswers from "src/api/goodpoint/useFetchStudentAnswers";
import DownloadButton from "src/components/ui/DownloadButton";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Loading from "src/components/ui/Loading";
import AnswersStructure from "src/pages/goodpoint/assessment/tabs/answers/structure/AnswersStructure";
import Divider from "src/components/layout/Divider";
import { addKeysToSubquestions } from "src/components/ui/Helpers";

function DownloadStudentAnswers({ assessmentId, studentId, ...props }) {
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.StudentAnswers.Download(
                assessmentId,
                "ocr",
                studentId
            )}
            filename={`${assessmentId}_student_answers_${studentId}.pdf`}
            className="download-student-answers float-right"
            text={`Download Answers`}
            {...props}
        />
    );
}

function StudentAnswers() {
    const { assessmentObject, selectedStudent } = useContext(AssessmentContext);

    const assessmentId = assessmentObject.id;
    const studentId = selectedStudent?.student_id;

    if (!selectedStudent) {
        return <Loading />;
    }

    var answers = Object.values(selectedStudent["student_answers"]).sort(
        (answer1, answer2) => {
            return answer1.question_number - answer2.question_number;
        }
    );

    return (
        <div className="student-answers">
            <div className="answers-container">
                {answers != null ? (
                    <AnswersStructure
                        selectedStudent={selectedStudent}
                        structureMap={answers}
                    />
                ) : (
                    <Loading />
                )}
            </div>
            {selectedStudent && (
                <DownloadStudentAnswers
                    assessmentId={assessmentId}
                    studentId={studentId}
                />
            )}
        </div>
    );
}

export default function AnswersContent() {
    const { assessmentObject } = useContext(AssessmentContext);
    const fetchStudentAnswers = useFetchStudentAnswers(assessmentObject.id);

    const { setSelectedStudent } = useContext(AssessmentContext);
    const { students, setStudents } = useContext(AssessmentContext);

    useEffect(() => {
        if (students) return;

        let answers = fetchStudentAnswers();
        if (answers) {
            for (const student in answers) {
                if (answers.hasOwnProperty(student)) {
                    const data = answers[student]["student_answers"];
                    answers[student]["student_answers"] = addKeysToSubquestions(data);
                }
            }
            setSelectedStudent(answers[Object.keys(answers)[0]]);
            setStudents(answers);
        }
    })

    return (
        <div className="answers-content flex-col gap-mid">
            <StudentsSelect />
            <Divider
                lineColour="rgba(0, 0, 0, 0.1)"
                shadowColour="rgba(0, 0, 0, 0.3)"
            />
            <StudentAnswers />
        </div>
    );
}
