import { Search, Xmark } from "iconoir-react";
import { useEffect, useRef, useState } from "react";

import "./SearchBar.css";


export default function SearchBar({ onChangeSearchText, clearRef=null, onClear=null }) {
    const inputRef = useRef(null);
    const [searchText, setSearchText] = useState(null);
    const [changed, setChanged] = useState(false);

    function onInputTextChange(textContent) {
        setChanged(true);
        if (textContent.length === 0) {
            setSearchText(null);
            clearInputText();
        } else {
            setSearchText(textContent);
        }
    }

    function clearInputText() {
        setChanged(true);
        setSearchText(null);
        inputRef.current.value = "";
        onClear && onClear();
    }

    clearRef.current = clearInputText;

    useEffect(() => {
        if (!changed) return;
        onChangeSearchText(searchText ?? "");
        setChanged(false);
    }, [changed, onChangeSearchText, searchText]);

    return <div 
        className="rubric-search flex-row gap-small align-center cursor-text"
        onClick={() => {inputRef.current.focus();}}
    >
        <Search />
        <input
            className="rubric-search-input"
            ref={inputRef}
            placeholder="Search..."
            onChange={(e) => onInputTextChange(e.target.value)}
        />
        <div className={`rubric-search-clear ${searchText ? "cursor-pointer" : ""}`} onClick={clearInputText}>
            <Xmark strokeWidth={searchText ? 1.5 : 0} />
        </div>
    </div>
}