import React from 'react';
import { Link } from 'react-router-dom';
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';

function AdminPage() {
    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <Title 
                    title="Admin Portal"/>
                <div className="flex-col gap-4">
                    <Link to="/newswitch/public-config" className='p-2 rounded outline outline-1 bg-orange-300 flex-row gap-4'>
                        <h3>Configure Public NewsWitch Jobs</h3>
                    </Link>
                    <Link to="/newswitch/pipeline-tracker" className='p-2 rounded outline outline-1 bg-orange-300 flex-row gap-4'>
                        <h3>NewsWitch Pipeline Tracker</h3>
                    </Link>
                    <Link to="/admin/users" className='p-2 rounded outline outline-1 bg-orange-300 flex-row gap-4'>
                        <h3>User Management</h3>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AdminPage;