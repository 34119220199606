import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import axios from 'axios';

import "./ClassStats.css";
import ClassGeneralFeedback from "src/pages/goodpoint/assessment/tabs/grading/class-performance/ClassGeneralFeedback";
import { AiGenerated } from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import { getClassDistImgSrc, saveClassDistImgSrc } from "src/storage/GradingStorage";
import { LoadingCharts } from "src/components/ui/LoadingCharts";

function ClassResults({ examResults }) {
    return <>
        <div className="flex-row justify-content-space-between">
            <div className="flex-row align-baseline gap-small">
                <b>Class Results</b>
                <AiGenerated />
            </div>
            <p>Statistics for the whole class</p>
        </div>
        <div className="class-average flex-row align-center">
            <h1>{examResults && parseFloat(examResults.class_percent?.toFixed(1))}%</h1>
            <div>
                <p>{examResults?.class_mark.toFixed(1) ?? undefined}/{examResults?.total_mark} Marks</p>
                <p>Grade {examResults?.class_grade}</p>
            </div>
        </div>
    </>;
}

export default function ClassStats({ examResults }) {
    const { assessmentObject } = useContext(AssessmentContext);
    const assessmentId = assessmentObject.id;

    const [classDistImgSrc, setClassDistImg] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loading, setLoading] = useState(true); 



    useEffect(() => {
        setLoading(true); 
    
        const cachedSrc = getClassDistImgSrc(assessmentId);
        if (cachedSrc) {
          setClassDistImg(cachedSrc);
          setLoading(false);
          return;
        } 
    
        const endpoint = GoodPointApi.Grading.Images(assessmentId, GradingImages.OVERALL_DIST);
        axios.get(endpoint, { responseType: 'blob' })
          .then(response => {
            const blob = response.data;
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64Img = reader.result;    
              setClassDistImg(base64Img);    
              saveClassDistImgSrc(assessmentId, base64Img);
              setLoading(false); 
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => {
            console.error('Error fetching class distribution image:', error);
            setLoading(false); 
          });
    
      }, [assessmentId]);
    
    return <>
        <div className="class-stats border-mid rounded-small fill-width padding-large">
            <ClassResults examResults={examResults} />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            {loading ? (
                <LoadingCharts />
            ) : (
                <img
                    className="cursor-pointer"
                    src={classDistImgSrc}
                    alt="Class Overall Marks Distribution"
                    onClick={() => { setPopupOpen(true); }}
                />
            )}

            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            <ClassGeneralFeedback />
        </div>

        <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
            <div className='bg-white rounded-small'>
                <img src={classDistImgSrc} alt="Class Overall Marks Distribution" />
            </div>
        </Popup>
    </>
}