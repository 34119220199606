import "./ExamStructure.css";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import { ExamStructureContext } from "src/pages/goodpoint/assessment/components/structure/ExamStructureContext";
import {
  useVisibility,
  Visibility,
  VisibilityContext,
} from "src/pages/goodpoint/assessment/components/structure/Visibility";

import { Check, InfoCircleSolid, ListSelect, Xmark } from "iconoir-react";
import SearchBar from "src/components/content/SearchBar";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AssessmentContext,
  EDIT_MODES,
} from "src/pages/goodpoint/assessment/AssessmentContext";
import {
  isRubric,
  isStudentPerformance,
  makeCards,
  reorderStructureByTags,
} from "src/components/ui/Helpers";
import update from "immutability-helper";
import Sortable from "src/pages/goodpoint/assessment/components/Sortable";
import EditToolbar from "src/components/ui/EditToolbar";
import { IconButton as IconButtonMui, Tooltip } from "@mui/material";
import IconButton from "src/components/ui/IconButton";

function ShowHideButton({ isSelectionMode, setIsSelectionMode }) {
  const { clear, hiddenCount } = useContext(VisibilityContext);
  return (
    <div className="flex-row align-center gap-mid">
      <IconButton
        Icon={isSelectionMode ? Check : ListSelect}
        text={isSelectionMode ? "Finish" : "Select visible"}
        borderSize="0"
        bgColour={isSelectionMode ? "var(--orange-mid)" : "var(--orange-light)"}
        paddingSize="var(--padding-small)"
        onClick={() => setIsSelectionMode((mode) => !mode)}
      />
      {hiddenCount > 0 && !isSelectionMode && (
        <div
          className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
          onClick={() => clear()}
        >
          <p>{hiddenCount} total hidden (Clear)</p>
          <Xmark strokeWidth={1.0} />
        </div>
      )}
    </div>
  );
}

export function ExamStructure({
  structureMap,
  setStructure = (_s) => {},
  saveStructure = (_id, _s, _e) => {},
  addStructureUpdate = (_u) => {},
  HeaderComponent,
  ItemComponent,
  itemCommonProps = null,
  initiallyExpanded = false,
  editMode = EDIT_MODES.DEFAULT,
  setEditMode = () => {},
}) {
  const { assessmentObject } = useContext(AssessmentContext);
  const assessmentId = assessmentObject.id;

  const expandCollapse = useExpandCollapse(initiallyExpanded);
  const visibilityContextValue = useVisibility(structureMap);
  const { clear, visibilityMap } = visibilityContextValue;
  const countHiddenQuestions = Object.values(visibilityMap).filter(
    (q) => q.visibility in [Visibility.HIDDEN, Visibility.PARTIALLY_HIDDEN]
  ).length;

  const searchVisibility = useVisibility(structureMap);
  const { clear: clearSearchVisibility } = searchVisibility;

  const [isSelectionMode, setIsSelectionMode] = useState(false);

  const [searchTerms, setSearchTerms] = useState([]);

  const clearSearchBar = useRef(() => {});

  //this is what reorder mode shows
  const [cards, setCards] = useState(makeCards(structureMap));

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) => {
      const newCards = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });

      return newCards;
    });
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setStructure((prevStructure) => {
      const newStructure = reorderStructureByTags(cards, prevStructure);
      saveStructure(
        assessmentId,
        newStructure,
        itemCommonProps?.enhanced ?? false
      );
      return newStructure;
    });
  }, [cards]);
  /* eslint-enable react-hooks/exhaustive-deps */


  const renderCard = useCallback((card, index) => {
    return (
      <Sortable
        key={card.id}
        index={index}
        id={card.id}
        header={card.tag}
        moveCard={moveCard}
      />
    );
  }, [moveCard]);

  const structureItems = useMemo(() => {
    return Object.entries(structureMap)
      .sort(
        ([, a], [, b]) => Number(a.question_number) - Number(b.question_number)
      ) // Convert index to number for correct sorting
      .map(([itemKey, itemData]) => (
        <ItemComponent
          key={itemKey}
          itemData={itemData}
          outerTags={[]}
          {...(itemCommonProps ?? {})}
        />
      ));
  }, [structureMap, itemCommonProps]);

  const structureSortables = () => {
    return <>{cards.map((card, i) => renderCard(card, i))}</>;
  };

  function onChangeSearchText(text) {
    const newSearchTerms = text
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
    setSearchTerms(newSearchTerms);
  }

  function clearSearchTerms() {
    clearSearchVisibility();
    setSearchTerms([]);
    setIsSelectionMode(false);
  }

  return (
    <ExpandCollapseContext.Provider value={expandCollapse}>
      <ExamStructureContext.Provider
        value={{
          structureMap,
          ItemComponent,
          isSelectionMode,
          itemCommonProps: itemCommonProps ?? {},
          searchTerms,
          clearSearch: () => clearSearchBar.current(),
          searchVisibility,
        }}
      >
        <VisibilityContext.Provider value={visibilityContextValue}>
          <div className="exam-structure">
            <div className="flex-col gap-large">
              <HeaderComponent structureMap={structureMap} />
              <div className="exam-structure-header flex-row justify-content-space-between">
                <div className="flex-row gap-large align-center">
                  <SearchBar
                    clearRef={clearSearchBar}
                    onChangeSearchText={onChangeSearchText}
                    onClear={clearSearchTerms}
                  />
                  <ShowHideButton
                    isSelectionMode={isSelectionMode}
                    setIsSelectionMode={setIsSelectionMode}
                  />
                </div>
                <ExpandCollapseButtons />
              </div>
            </div>
            <div className="structure-content flex-col gap-mid">
              {countHiddenQuestions > 0 && !isSelectionMode && (
                <div
                  className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                  onClick={() => clear()}
                >
                  <p>{`${countHiddenQuestions} hidden question${
                    countHiddenQuestions > 1 ? "s" : ""
                  } (Clear)`}</p>
                  <Xmark strokeWidth={1.0} />
                </div>
              )}
              {isRubric(structureMap) && (
                <EditToolbar
                  editMode={editMode}
                  setEditMode={setEditMode}
                  setStructure={setStructure}
                  setCards={setCards}
                  saveStructure={saveStructure}
                  addStructureUpdate={addStructureUpdate}
                  enhanced={itemCommonProps.enhanced}
                />
              )}
               {isStudentPerformance(structureMap) && (
                <div className="flex">
                  <div className="ml-auto">
                    <Tooltip title="Click on marks to edit" placement="right">
                      <span>
                        <IconButtonMui disabled>
                          <InfoCircleSolid className="text-gray-500" />
                        </IconButtonMui>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              )}
              <div className="structure-content flex-col gap-small">
                {editMode !== EDIT_MODES.REORDER && structureItems}
                {editMode === EDIT_MODES.REORDER && structureSortables()}
              </div>
            </div>
          </div>
        </VisibilityContext.Provider>
      </ExamStructureContext.Provider>
    </ExpandCollapseContext.Provider>
  );
}
