
function NewsLists({ ticker, newsList }) {
    const dateLists = {};
    newsList.forEach(item => {
        if (!item["sentiment"]["direction"] || item["sentiment"]["direction"] === "Irrelevant") return;
        let date = new Date(item["date"] * 1000).toDateString();
        if (date === "Invalid Date") {
            date = new Date(item["date"]).toDateString();
        }
        if (!dateLists[date]) {
            dateLists[date] = [];
        }
        dateLists[date].push(item);
    });
    return (
        <details>
            <summary>
                <h2>News summaries for {ticker}</h2>
            </summary>
            {
                Object.entries(dateLists).sort(([d1, v1], [d2, v2]) => ((+ (new Date(d1) < new Date(d2))) * 2) - 1).map(([date, newsList]) => (
                    <details>
                        <summary>
                            Published Date: {date}
                        </summary>
                        <NewsList title="Positive News" newsList={newsList.filter(item => item["sentiment"]["direction"]?.includes("Positive"))} />
                        <NewsList title="Negative News" newsList={newsList.filter(item => item["sentiment"]["direction"]?.includes("Negative"))} />
                        <NewsList title="Neutral News (some irrelevant)" newsList={newsList.filter(item => item["sentiment"]["direction"] === "Neutral")} />
                    </details>
                ))
            }
        </details>
    );
}

function NewsList({ title, newsList }) {
    if (newsList.length === 0) return null;
    return (
        <details>
            <summary>
                {title}
            </summary>
            <ul>
                {
                    newsList.map(item => {
                        return (
                            <li className="news-item">
                                <a href={item.url} target="_blank" rel="noopener noreferrer">Link to the news 🌐</a>
                                <br />
                                <br />
                                <div className="flex-row justify-between w-full">
                                    <span className="text-sm">Sentiment: {item["sentiment"]["direction"]} - {item["sentiment"]["intensity"]}</span>
                                    <span className="text-sm font-light">Source: {item["domain"]}</span>
                                </div>
                                <br />
                                <b className="text-sm">{item.title}</b>
                                <br />
                                <br />
                                {item["summary"] !== null && item["summary"] !== "" ? item["summary"] : item["snippet"]}
                                <br />
                            </li>
                        );
                    })
                }
            </ul>
        </details>
    )
}

export default NewsLists;