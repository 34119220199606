import "./StudentPerformance.css"
import { useContext, useEffect, useMemo, useState } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { useFetchAllStudentResults } from "src/api/goodpoint/useFetchResults";
import Divider from "src/components/layout/Divider";
import DownloadButton from "src/components/ui/DownloadButton";
import Loading from "src/components/ui/Loading";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";
import GradingStudentsList from "src/pages/goodpoint/assessment/tabs/grading/student-performance/GradingStudentsList";
import StudentQuestionFeedback from "src/pages/goodpoint/assessment/tabs/grading/student-performance/student-feedback/StudentFeedback";
import StudentStats from "src/pages/goodpoint/assessment/tabs/grading/student-performance/StudentStats";


function DownloadStudentFeedback({ ...props }) {
    const {assessmentObject} = useContext(AssessmentContext);
    const {selectedStudent} = useContext(StudentsContext);
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Grading.Feedback.Download(assessmentObject.id, selectedStudent.id)}
            filename={`${assessmentObject.id}_feedback_student_${selectedStudent.id}.pdf`}
            className="float-right justify-self-right"
            text={`Download Student Feedback`}
            style={{width: "min-content"}}
            {...props}
        />
    )
}

export default function StudentPerformance({ examResults }) {
    const { assessmentObject } = useContext(AssessmentContext);
    const fetchAllStudentResults = useFetchAllStudentResults(assessmentObject.id);
    const [selectedStudent, setSelectedStudent] = useState(null);
    //i will fix this later
    // eslint-disable-next-line
    const [students, setStudents] = useState(null);
    const [questionFeedback, setQuestionFeedback] = useState(null);

    const [orderBy, setOrderBy] = useState("grade");
    const [isAscendingOrder, setIsAscendingOrder] = useState(false);

    // const [studentGrading, setStudentGrading] = useState(null);
    //i think studentGarding needs to be a state for the thing in the student select to update correctly
    const studentGrading = useMemo(() => {
        const result = fetchAllStudentResults();
        return result;
    }, [fetchAllStudentResults]);

    // useEffect(() => {
    //     if (studentGrading) return;

    //     let result = fetchAllStudentResults();
    //     if (result){
    //         setStudentGrading(result);
    //     }
    // })

    const sortedStudents = useMemo(() => {
        if (!studentGrading || !examResults) return null;
        return Object
            .values(studentGrading)
            .sort((student1, student2) => {
                var result = (orderBy === "grade")
                    ? (student1["total_marks"] / examResults.total_mark) - (student2["total_marks"] / examResults.total_mark)
                    : (
                        (student1[orderBy] && student2[orderBy])
                            ? student1[orderBy] - student2[orderBy]
                            : student1.student_id.localeCompare(student2.student_id)
                    );
                return isAscendingOrder ? result : -result;
            });
    }, [studentGrading, examResults, orderBy, isAscendingOrder]);

    useEffect(() => {
        if (selectedStudent || !sortedStudents || (sortedStudents.length === 0)) return;
        setSelectedStudent(sortedStudents[0]);
        setStudents(sortedStudents);
    }, [selectedStudent, sortedStudents]);

    useEffect(() => {
        if (!selectedStudent || !sortedStudents) return;
        if (typeof selectedStudent === "string") {
            const ss = sortedStudents.find((student) => student.student_id === selectedStudent);
            setSelectedStudent(ss);
        }
        setStudents(sortedStudents);

    }, [selectedStudent, sortedStudents]);

    if (!selectedStudent) {
        return <Loading />;
    }

    return (
        <div className="results-content fill-width fill-height flex-col gap-large">
            <StudentsContext.Provider value={{
                students,
                setStudents,
                selectedStudent, 
                setSelectedStudent, 
                questionFeedback, 
                setQuestionFeedback
            }}>
                <GradingStudentsList 
                    {...{examResults, orderBy, setOrderBy, isAscendingOrder, setIsAscendingOrder}}
                />
                <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
                <StudentStats examResults={examResults} />
                <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
                <b>Marking Per Question</b>
                <StudentQuestionFeedback />
                <div>
                    <DownloadStudentFeedback />
                </div>
            </StudentsContext.Provider>
        </div>
    )
}