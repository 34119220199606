import { Collapse, Expand } from "iconoir-react";
import { useContext, useState, useEffect } from "react";
import { useCollapse } from "react-collapsed";
import { useFetchStudentFeedback } from "src/api/goodpoint/useFetchFeedback";
import IconButton from "src/components/ui/IconButton";
import Loading from "src/components/ui/Loading";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { AiGenerated } from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { IconButton as MuiIconButton } from "@mui/material";
import { EditPencil } from "iconoir-react";
import { saveGradingOverallFeedbackById } from "src/storage/GradingStorage";
import RenderMath from "src/components/ui/RenderMath";
import { tokenizeText } from "src/components/ui/Helpers";

export default function StudentOverallFeedback() {
  const { assessmentObject } = useContext(AssessmentContext);
  const { selectedStudent } = useContext(StudentsContext);
  const [isEdit, setIsEdit] = useState(false);
  const [thisOverallFeedback, setThisOverallFeedback] = useState() //this became tokens 
  const fetchOverallFeedback = useFetchStudentFeedback(assessmentObject.id, selectedStudent?.student_id);

  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } = useCollapse({
    collapsedHeight: 120,
    hasDisabledAnimation: true
  });

  useEffect(() => {
    setThisOverallFeedback([]); //so the student feedback becomes blank when switching to another student
    const result = fetchOverallFeedback();
    if (result) {
      setThisOverallFeedback(tokenizeText(result));
    }
  }, [fetchOverallFeedback, selectedStudent]);

  function saveContent({ newContent }) {
    setThisOverallFeedback(tokenizeText(newContent));
    saveGradingOverallFeedbackById(assessmentObject.id, selectedStudent?.student_id, newContent);
  }

  return (
    <div className="student-feedback">
      <div className="feedback-header flex-row justify-content-space-between align-center">
        <div className="flex-row section-heading-left gap-small">
          <b>Student Feedback</b>
          <AiGenerated />
        </div>
        <div className="section-heading-controls flex flex-row gap-4">
          {!isEdit && (
            <MuiIconButton
              onClick={() => {
                setIsEdit(true);
                setExpanded(true);
              }}
            >
              <EditPencil className="cursor-pointer hover:text-blue-700" />
            </MuiIconButton>
          )}
          <IconButton
            Icon={isExpanded ? Collapse : Expand}
            text={isExpanded ? "Collapse" : "Expand"}
            bgColour={isExpanded ? "var(--orange-mid)" : "var(--yellow-light)"}
            paddingSize="2px 5px"
            borderSize="var(--border-thin)"
            {...getToggleProps()}
          />
        </div>
      </div>
      {thisOverallFeedback ? (
        <div {...getCollapseProps()}>
          {thisOverallFeedback &&
            (isEdit ? (
              <RichMathEditor
                initialTokens={thisOverallFeedback}
                saveContent={saveContent}
                closeEditor={() => setIsEdit(false)}
              />
            ) : (
              <RenderMath tokens={thisOverallFeedback} />
            ))}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}