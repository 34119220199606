import { useContext, useMemo } from "react";
import "./StudentsMenu.css";
import Loading from "src/components/ui/Loading";
import { AssessmentContext } from "../AssessmentContext";
import Select, { StylesConfig } from "react-select";

export const customStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--yellow-light)', // Same background as your original example
    borderRadius: '8px', // Border radius as per your original
    boxShadow: '0 0 0 1px var(--orange-mid)', // Optional: subtle shadow matching the border color
    ':hover': {
      borderColor: 'var(--yellow-light)', // Yellow border on hover
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? 'var(--orange-mid)' : isFocused ? 'var(--yellow-light)' : undefined, // Orange for selected and yellow for hover
    color: isSelected ? 'white' : 'black', // White text when selected, black otherwise
    cursor: 'pointer',
    ':active': {
      ...styles[':active'],
      backgroundColor: isSelected ? 'var(--orange-mid)' : 'var(--yellow-light)', // Same active state as hover
    },
    transition: 'background-color 0.15s ease-in-out', // Smooth transition for background color
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#ccc',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black', // Style for the selected value (default to black text)
  }),
};

export function StudentsSelect() {
  const { selectedStudent, setSelectedStudent, students } = useContext(AssessmentContext);

  const studentOptions = Object.entries(students ?? {}).map(
    ([_studentId, studentData]) => {
      const questionsAnswered = Object.keys(studentData.student_answers).length;
      return {
        value: _studentId,
        label: (
          <div className="student-button-grid fill-width">
              <p className="student-name justify-self-left">
                <b>{studentData.name ?? studentData.student_id}</b>
              </p>
              {studentData.name && (
                <p className="student-id">{studentData.student_id}</p>
              )}
              <p className="questions-answered justify-self-right">
                {questionsAnswered} question{questionsAnswered > 1 ? "s" : ""}{" "}
                answered
              </p>
            </div>
        )
      };
    }
  );

  const handleStudentChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedStudent(students[selectedOption.value]);
    }
  };

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        value={studentOptions.find(
          (option) => option.value === selectedStudent?.student_id
        )}
        styles={customStyles} // Apply the custom styles
        isClearable={false} // Not clearable
        isSearchable={true} // Searchable
        name="student"
        options={studentOptions}
        onChange={handleStudentChange}
        placeholder="Select a student"
      />
    </>
  );
}


export function StudentButton({ studentData }) {
  const { selectedStudent, setSelectedStudent } = useContext(AssessmentContext);
  const isSelected = selectedStudent?.student_id === studentData.student_id;
  const questionsAnswered = Object.keys(studentData.student_answers).length;

  return (
    <button
      className={
        `student-button fill-width clickable rounded-small padding-small border-thin outline-thin ` +
        `${
          isSelected
            ? "bg-orange-mid outline-black"
            : "bg-yellow-light outline-transparent"
        }`
      }
      onClick={() => setSelectedStudent(studentData)}
    >
      <div className={`student-button-grid fill-width`}>
        <p className="student-name justify-self-left">
          <b>{studentData.name ?? studentData.student_id}</b>
        </p>
        {studentData.name && (
          <p className="student-id">{studentData.student_id}</p>
        )}
        <p className="questions-answered justify-self-right">
          {questionsAnswered} question{questionsAnswered > 1 ? "s" : ""}{" "}
          answered
        </p>
      </div>
    </button>
  );
}

export function StudentsMenu({
  style = {},
  StudentButtonComponent = StudentButton,
  extraProps = {},
}) {
  const { students } = useContext(AssessmentContext);

  const studentsList = useMemo(() => {
    return Object.entries(students ?? {}).map(
      ([_studentId, studentData], i) => {
        return (
          <StudentButtonComponent
            key={i}
            studentData={studentData}
            {...extraProps}
          />
        );
      }
    );
  }, [students, extraProps]);

  if (!students) {
    return <Loading />;
  }

  return (
    <div
      className="students-list flex-col gap-mid justify-content-left"
      style={style}
    >
      {studentsList}
    </div>
  );
}
