import "src/pages/newswitch/components/ReportContainer.css";
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import TickerSearchBar from './components/TickerSearchBar';
import useResetScroll from "src/hooks/useResetScroll";
import { useEffect, useState } from 'react';
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";
import NewsLists from './components/NewsLists';
import PPPChart from './components/PPPChart';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material';

function NewNewsWitchDasboard() {

    const callAPI = useNewsWitchApi();

    const [ticker, setTicker] = useState(undefined);
    const [tickerToSearch, setTickerToSearch] = useState(undefined);
    const [news, setNews] = useState([]);
    const [startDate, setStartDate] = useState(() => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        return yesterday;
    });
    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        return today;
    });

    const [triggerSearch, setTriggerSearch] = useState(false);

    // TODO: If you change the date or ticker while it is loading, it should cancel the previous request and start a new one.
    useEffect(() => {
        if (tickerToSearch === undefined) return;
        if (startDate === undefined || endDate === undefined) return;
        if (!triggerSearch) return;
        setNews([]);
        callAPI("GET", "/news?" + new URLSearchParams({
            ticker: tickerToSearch,
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString()
        }).toString())?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    if (body["news"][tickerToSearch] === undefined) {
                        setNews([]);
                        setTicker(tickerToSearch);
                        setTriggerSearch(false);
                        return;
                    }
                    setNews(body["news"][tickerToSearch]);
                    setTicker(tickerToSearch);
                    setTriggerSearch(false);
                });
            } else {
                console.error(response);
                setNews([]);
                setTicker(tickerToSearch);
                setTriggerSearch(false);
            }
        });
    }, [callAPI, tickerToSearch, startDate, endDate, triggerSearch]);

    useResetScroll();

    const onChange = (start, end) => {
        const newStartDate = start.toDate();
        newStartDate.setHours(0, 0, 0, 0);

        const newEndDate = end.toDate();
        newEndDate.setHours(23, 59, 59, 999);

        if (newStartDate === undefined || newEndDate === undefined) return;

        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setTriggerSearch(true);
    }

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <Title 
                    title="NewsWitch"
                    subtitle=""
                    
                />
                <div className='flex-col gap-4 report-container w-full'>
                    <div className="grid grid-cols-2 gap-4">
                        <TickerSearchBar onSelect={(selection) => {setTickerToSearch(`${selection['exchange']}:${selection['ticker']}`); setTriggerSearch(true)}} maxNameLength={100} />
                        <DateRangePicker onChange={onChange} startDefault={startDate} endDefault={endDate} />
                    </div>
                    {
                        triggerSearch && tickerToSearch && <p>Loading news for <b>{tickerToSearch}</b></p>
                    }
                    { news && news.length > 0 &&
                        <>
                        <div className="section w-full">
                            <NewsLists newsList={news} ticker={ticker} />
                        </div>
                        <div className="w-full">
                            <PPPChart news={news} eventDate={endDate}/>
                        </div>
                        </>
                    }
                    {
                        news && news.length === 0 && !triggerSearch && ticker !== undefined && <p>No news found for <b>{ticker}</b>.</p>
                    }
                </div>
            </div>
        </div>
    );
}

function DateRangePicker({ onChange = (start, end) => {}, startDefault = undefined, endDefault = undefined}) {
    
    const theme = createTheme({
        palette: {
            primary: {
                main: "#F49569",
                dark: "#EF753D"
            },
            secondary: {
                main: "#F49569"
            }
        },
        typography: {
            fontSize: 11,
        },
    });

    const [startDate, setStartDate] = useState(startDefault ? dayjs(startDefault) : dayjs().add(-1, 'day'));
    const [endDate, setEndDate] = useState(endDefault ? dayjs(endDefault) : dayjs());

    const setDateRange = (isStart, date) => {
        if (isStart && !date.isAfter(endDate)) {
            setStartDate(date);
            onChange(date, endDate);
        } else if (!isStart && !date.isBefore(startDate)) {
            setEndDate(date);
            onChange(startDate, date);
        } else if (isStart && date.isAfter(endDate)) {
            setStartDate(date);
            setEndDate(date);
            onChange(date, date);
        } else if (!isStart && date.isBefore(startDate)) {
            setStartDate(date);
            setEndDate(date);
            onChange(date, date);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <div className="flex flex-row gap-2 justify-end items-center max-w-72 flex-grow-0 flex-shrink-0 flex-auto">
                    <DatePicker
                        format='DD MMM YYYY'
                        value={startDate}
                        onChange={(event) => setDateRange(true, event)}
                        disableFuture={true}
                    />
                    <p className="text-center">-</p>
                    <DatePicker
                        format='DD MMM YYYY'
                        value={endDate}
                        onChange={(event) => setDateRange(false, event)}
                        disableFuture={true}
                    />
                </div>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

export default NewNewsWitchDasboard;
