import { useCallback, useEffect, useState, useRef } from "react";
import "./AssessmentPage.css";
import BackLink from "src/components/nav/BackLink";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Divider from "src/components/layout/Divider";
import FooterControlBar from "src/pages/goodpoint/assessment/FooterControlBar";
import { AssessmentTabs } from "src/pages/goodpoint/assessment/tabs/Tabs";
import useCallApi from "src/api/useCallApi";
import { useTabsState } from "src/pages/goodpoint/assessment/tabs/useTabsState";
import { Tab } from "src/api/goodpoint/Const";
import { getCurrentTabById, setCurrentTabById } from "src/storage/CurrentTab";
import { saveRubric } from "src/storage/RubricStorage";

import {
  getAssessmentObjectById,
  saveAssessmentObject,
} from "src/storage/AssessmentObject";
import {
  AssessmentContext,
  EDIT_MODES,
} from "src/pages/goodpoint/assessment/AssessmentContext";
import EditableAssessmentName from "src/pages/goodpoint/assessment/EditableAssessmentName";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import InfoTab from "src/pages/goodpoint/assessment/tabs/info/InfoTab";
import RubricTab from "src/pages/goodpoint/assessment/tabs/rubric/RubricTab";
import AnswersTab from "src/pages/goodpoint/assessment/tabs/answers/AnswersTab";
import GradingTab from "src/pages/goodpoint/assessment/tabs/grading/GradingTab";
import Loading from "src/components/ui/Loading";
import useSendConfig from "src/api/goodpoint/useSendConfig";
// import useSendRubric from "src/api/goodpoint/useSendRubric";

const TabComponentMap = {
  [Tab.INFO]: InfoTab,
  [Tab.RUBRIC]: RubricTab,
  [Tab.ANSWERS]: AnswersTab,
  [Tab.GRADING]: GradingTab,
};

function LoadingAssessment() {
  return (
    <div className="centered">
      <div className="container assessment-details">
        <BackLink prevPage="AI Grader" href="/goodpoint" />
        <Loading size={64} />
      </div>
    </div>
  );
}

export default function AssessmentPage() {
  const { assessmentId } = useParams();
  //   Rubric context
  const [rubric, setRubric] = useState(null);
  //question feedback
  const [questionFeedback, setQuestionFeedback] = useState(null);
  //student feedback
  const [studentFeedback, setStudentFeedback] = useState(null);
  //class feedback
  const [classFeedback, setClassFeedback] = useState(null);
  //student answers
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [students, setStudents] = useState(null);

  const [editMode, setEditMode] = useState(EDIT_MODES.DEFAULT);
  const rubricUpdates = useRef(new Set()); // Use useRef to persist rubricUpdates

  const addRubricUpdate = (newRubricUpdate) => {
    const currentRubricUpdates = rubricUpdates.current;

    // Check for duplicates based on content
    const isDuplicate = Array.from(currentRubricUpdates).some(
      (update) => JSON.stringify(update) === JSON.stringify(newRubricUpdate)
    );

    if (!isDuplicate) {
      currentRubricUpdates.add(newRubricUpdate);
    }
  };
  // const sendRubric = useSendRubric(assessmentId, setRubric);

  const callApi = useCallApi();

  const [assessmentObject, setAssessmentObject] = useState(
    getAssessmentObjectById(assessmentId)
  );
  const [queriedServer, setQueriedServer] = useState(false);

  const updateAssessmentObjectWithLastModified = useCallback((updates) => {
    setAssessmentObject((obj) => {
      const newObj = {
        ...obj,
        ...updates,
        last_modified: dayjs().unix(),
      };
      saveAssessmentObject(newObj);
      return newObj;
    });
  }, []);

  const sendConfig = useSendConfig(
    assessmentId,
    updateAssessmentObjectWithLastModified
  );

  const { tabsState, currentTab, nextTab, futureTab, changeTab } = useTabsState(
    {
      assessmentObject,
      startTab: getCurrentTabById(assessmentId),
      onChangeTab: (oldTab, newTab) => {
        if (oldTab === Tab.INFO) {
          sendConfig(assessmentObject.exam_config);
        }
        if (oldTab === Tab.RUBRIC) {
          // sendRubric(assessmentId, rubric, rubricUpdates.current);
          saveRubric(assessmentId, rubric);
        }
        setCurrentTabById(assessmentId, newTab);
      },
    }
  );

  const [footerControls, setFooterControls] = useState(null);
  const [deleteSelected, setDeleteSelected] = useState([]);

  // Fetch assessment object from API on load
  useEffect(() => {
    if (queriedServer) return;
    callApi("GET", GoodPointApi.Assessment(assessmentId))?.then((response) => {
      if (response.status === 200) {
        response.json().then((body) => {
          const exam = body;
          delete exam["success"];
          if (
            !assessmentObject ||
            exam["last_modified"] > assessmentObject.last_modified
          ) {
            setAssessmentObject({
              ...assessmentObject,
              ...exam,
            });
          } else {
            setAssessmentObject({
              ...assessmentObject,
              configure_step: exam["configure_step"],
              rubric_step: exam["rubric_step"],
              student_answers_step: exam["student_answers_step"],
              grading_step: exam["grading_step"],
            });
            sendConfig(assessmentObject.exam_config);
          }
          setQueriedServer(true);
        });
      } else {
        console.error(
          "Failed to fetch assessment: " +
            response.status +
            " " +
            response.statusText
        );
      }
    });
  }, [callApi, assessmentId, assessmentObject, queriedServer, sendConfig]);

  if (!assessmentObject || !tabsState) {
    return <LoadingAssessment />;
  }

  const TabComponent = TabComponentMap[currentTab];

  return (
    <div className="centered">
      <div className="container assessment-details">
        <AssessmentContext.Provider
          value={{
            assessmentObject: assessmentObject,
            updateAssessmentObject: updateAssessmentObjectWithLastModified,
            footerControls: footerControls,
            setFooterControls: setFooterControls,
            deleteSelected: deleteSelected,
            setDeleteSelected: setDeleteSelected,
            rubric,
            setRubric,
            editMode,
            setEditMode,
            rubricUpdates: rubricUpdates.current,
            addRubricUpdate,
            questionFeedback,
            setQuestionFeedback,
            studentFeedback,
            setStudentFeedback,
            classFeedback,
            setClassFeedback, 
            selectedStudent, 
            setSelectedStudent,
            students,
            setStudents
          }}
        >
          <BackLink prevPage="AI Grader" href="/goodpoint" />
          <EditableAssessmentName />
          <AssessmentTabs
            tabsState={tabsState}
            changeTab={changeTab}
            currentTab={currentTab}
          />
          <Divider
            lineColour="rgba(0, 0, 0, 0.1)"
            shadowColour="rgba(0, 0, 0, 0.3)"
          />
          <div className="assessment-content">
            <TabComponent />
          </div>
          {currentTab && (
            <FooterControlBar
              changeTab={changeTab}
              currentTab={currentTab}
              nextTab={nextTab}
              futureTab={futureTab}
              footerControls={footerControls}
            />
          )}
        </AssessmentContext.Provider>
      </div>
    </div>
  );
}
