// @ts-nocheck
import { useMemo, useState, useContext, useEffect } from "react";
import { StructureItem } from "../../../components/structure/StructureItem";
import StructureSection from "../../../components/structure/StructureSection";
import SolutionSteps from "src/pages/goodpoint/assessment/tabs/rubric/structure/SolutionSteps";
import { AssessmentContext } from "../../../AssessmentContext";
import { ExamStructureContext } from "../../../components/structure/ExamStructureContext";
// import "./RubricItem.css";
import { saveRubric } from "src/storage/RubricStorage";
import { Tooltip } from "@mui/material";
import {
  updateNestedStructure,
  adjustMarksBottomUp,
} from "src/components/ui/Helpers";

function RubricItemHeader({ itemData, nestingLevel }) {
  const {
    setRubric,
    assessmentId,
  } = useContext(AssessmentContext);
  const enhanced = useContext(ExamStructureContext).itemCommonProps.enhanced;

  const numParts = useMemo(() => {
    const subQuestions = itemData.sub_questions;
    return Object.keys(subQuestions ?? {}).length;
  }, [itemData.sub_questions]);

  const handleMarkChange = (event) => {
    const newMark = parseFloat(event.target.value) || 0;
    let updatedRubric;
    const newValues = {
      mark: newMark,
    };
    const keys = itemData.keys; // Assuming itemData.keys is an array like ['Question 1', 'a', 'i']
    // addRubricUpdate({
    //   action: "UPDATE_MARKS",
    //   info: {
    //     keys: keys,
    //     new_mark: newMark,
    //   },
    // });

    setRubric((previousRubric) => {
      updatedRubric = { ...previousRubric };
      updateNestedStructure(updatedRubric, keys, newValues, "mark"); //top down
      adjustMarksBottomUp(updatedRubric); //bottom up
      saveRubric(assessmentId, updatedRubric, enhanced);
      return updatedRubric;
    });
  };

  return (
    <div className="w-full">
      <div className="flex-row flex justify-between">
        <p className="">
          <b>
            {nestingLevel > 0 ? "Part " : ""}
            {itemData.tag}
          </b>
        </p>

        {numParts > 0 && (
          <p className="">
            {numParts} Parts
          </p>
        )}

        <Tooltip
          title={
            itemData.keys.length === 1
              ? "Adjusts all related subquestion marks."
              : Object.keys(itemData.sub_questions || {}).length === 0
              ? "Affects parent question's total marks."
              : "Impacts both subquestions and parent marks."
          }
          placement="right"
        >
          <div className="justify-self-right">
            <input
              type="number"
              key={`${itemData.tag}_${itemData.mark}`} //forces rerender
              onClick={(event) => event.stopPropagation()} //stops the item from expanding on click
              onBlur={handleMarkChange}
              defaultValue={itemData.mark}
              className="w-12 bg-transparent focus:bg-white focus:outline-accent text-right"
            />
            Marks
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export function RubricItemContent({ itemData }) {
  const enhanced = useContext(ExamStructureContext).itemCommonProps.enhanced;

  const [questionText, setQuestionText] = useState(itemData.question_text);
  const [description, setDescription] = useState(itemData.description);
  const [result, setResult] = useState(itemData.result);
  const [solutionText, setSolutionText] = useState(itemData.solution_text);
  const { rubric, setRubric, assessmentId, addRubricUpdate } =
    useContext(AssessmentContext);

  //for changing content
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!rubric || !itemData) return;

    const keys = itemData.keys; // Assuming question.keys is an array like ['Question 1', 'a', 'i']
    const newValues = {
      question_text: questionText,
      description: description,
      result: result,
      solution_text: solutionText,
    };

    const updatedRubric = { ...rubric };
    updateNestedStructure(updatedRubric, keys, newValues, "content");

    setRubric(updatedRubric);
    saveRubric(assessmentId, updatedRubric, enhanced);
  }, [questionText, description, result, solutionText]);
 /* eslint-enable react-hooks/exhaustive-deps */


  return (
    <div className="rubric-item-content">
      {itemData.question_text && (
        <StructureSection
          heading={"Question"}
          content={questionText}
          setContent={setQuestionText}
          addStructureUpdate={addRubricUpdate}
          question_keys={itemData.keys}
        />
      )}
      {enhanced && itemData.description && (
        <StructureSection
          heading={"Description"}
          content={description}
          setContent={setDescription}
          addStructureUpdate={addRubricUpdate}
          question_keys={itemData.keys}
          aiGenerated
        />
      )}
      {enhanced && itemData.result && (
        <StructureSection
          heading={"Result"}
          content={result}
          setContent={setResult}
          addStructureUpdate={addRubricUpdate}
          question_keys={itemData.keys}
          aiGenerated
        />
      )}
      {itemData.solution_text && (
        <StructureSection
          heading={"Solution"}
          content={solutionText}
          setContent={setSolutionText}
          addStructureUpdate={addRubricUpdate}
          question_keys={itemData.keys}
        />
      )}
      {enhanced && itemData.steps && (
        <SolutionSteps steps={itemData.steps} question_keys={itemData.keys} />
      )}
    </div>
  );
}

export function RubricItem(props) {
  const {
    editMode,
    setEditMode,
  } = useContext(AssessmentContext);

  return (
    <StructureItem
      HeaderComponent={RubricItemHeader}
      ContentComponent={RubricItemContent}
      editMode={editMode}
      setEditMode={setEditMode}
      {...props}
    />
  );
}

export function EnhancedRubricItem({ itemData, nestingLevel = 0 }) {
  return (
    <RubricItem
      itemData={itemData}
      enhanced={true}
      nestingLevel={nestingLevel}
    />
  );
}
